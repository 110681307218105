import React from 'react'
import Agreement from './components/agreement'
import { Title } from './atoms'
import { mainContent } from './config/privacy-policy-content'

const PrivacyPolicy = () => {
  const renderTitle = (
    <Title>
      <h1 className="title">Privacy Policy</h1>
      <h2 className="sub-title">This is a global Privacy Policy.</h2>
    </Title>
  )

  return <Agreement content={mainContent} renderTitle={renderTitle} />
}

export default PrivacyPolicy
