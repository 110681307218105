import React from 'react'

export const mainContent = [
  {
    title: '1. Introduction',
    content: [
      {
        text: [
          <>
            This Privacy Policy (“Policy”) details RAYWING PTE.LTD. ’s (“Avica”)
            commitment to protecting the privacy of individuals who visit our
            Websites and who register to use the products and Services (as
            defined below) which we market for subscription (“Subscriber”). For
            the purposes of this Policy, the term, “Websites”, shall refer
            collectively to{' '}
            <a className="link" href="/" target="_blank">
              www.avica.link
            </a>{' '}
            as well as any other websites operated by Avica and/or its
            affiliates that contain a link to this Policy.
          </>,
          'At Avica, we aim to be transparent about how your personal information is collected, used, and/or shared. The purpose of this Policy is to inform you of the types of personal information we may collect from you and how that information will be used. Avica collects certain personal information through its Websites as well as its family of products and/or related services (each a “Service” or the “Services”). Except as expressly set forth in this Policy, Avica will not use your information in any other manner or for any other purpose.',
          <>
            If you have any questions or wishes in connection with your personal
            data or with our privacy practices, you may contact us at our
            official email:{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
          </>,
        ],
      },
    ],
  },
  {
    title: '2. Scope of This Policy',
    content: [
      {
        text: [
          'In this Policy, personal information means information relating to an identified or identifiable natural person. An identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, or location data. The use of information collected through our Services shall be limited to the purpose of providing the Services for which the Subscriber has engaged.',
          'Our Websites may contain links to other websites and the information practices and the content of such other websites are governed by the privacy statements of such other websites. We are not responsible for the privacy practices of such other websites and we encourage you to review the privacy statements of any such other websites to understand their information practices.',
          'In certain cases, we may process your personal information as a Processor or Service Provider on behalf of our client Subscribers. If you are an individual who interacts with a Subscriber while using our Services (such as a customer of one of our Subscribers who are, for example, a Managed Service Provider or an IT support technician) and would either like to amend your contact information or no longer wish to be contacted by one of our Subscribers that use our Services, please contact the Subscriber that you interact with directly.',
        ],
      },
    ],
  },
  {
    title: '3. Privacy Policy Updates',
    content: [
      {
        text: [
          <>
            Due to the Internet’s rapidly evolving nature, we may need to update
            this Policy from time to time. If so, we will post the updated
            Privacy Policy on our Websites located at{' '}
            <a
              className="link"
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/privacy-policy.html
            </a>{' '}
            . We may also send registered users of the Websites email
            notifications notifying such users of any changes to the Policy.
          </>,
        ],
      },
    ],
  },
  {
    title: '4. What Information We Collect From You on our Websites',
    content: [
      {
        text: [
          'When visiting our Websites, you may be given the option to fill out a service registration, complete a survey or other form which could contain personally identifiable information, such as user identification name, an email address, and/or any other information that you voluntarily transmit with your communication to us.',
          'In addition, whenever you visit an Avica web page, your browser may send certain information to our server, which we record. This information may include the URL of the referring web page, your browser, OS type, as well as the information set forth below:',
        ],
      },
      {
        subTitle: 'Cookies and Other Tracking Technologies:',
        text: [
          <>
            To facilitate your use of our services，after obtaining your
            consent，we and our authorized partners may place cookies and other
            information gathering technologies on your browser. These
            technologies provide us with personal information, including
            information about devices and networks you utilize to access our
            Websites, and other information regarding your interactions with our
            Websites. For detailed information about the use of cookies in the
            Websites, please read and review our Cookie Policy which can be
            found at{' '}
            <a
              className="link"
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/privacy-policy.html
            </a>{' '}
            .
          </>,
          'We partner with third parties to manage our advertising on other sites. Our third party partners may also use technologies such as cookies to gather information about your activities on our Websites and other sites in order to suggest advertisements based upon your browsing activities and interests.',
          'We may use web beacons, tags and scripts on our Websites or in emails we send to you. These assist us in delivering cookies, counting visits to our Websites, understanding usage and campaign effectiveness and determining whether an email has been opened and acted upon. We may receive reports based on the use of these technologies from our third-party service providers on an individual and aggregated basis.',
          'We may use Local Storage Objects (“LSOs”) such as HTML5 to store content information and preferences. Various browsers may offer their own management tools for removing HTML5 LSOs. Third parties with whom we partner to provide certain features on our Websites or to display advertising based upon your Web browsing activity use LSOs such as HTML5 to collect and store information.',
        ],
      },
      {
        subTitle: 'Logs:',
        text: [
          'As is true with most websites and services delivered over the Internet, we gather certain information and store it in log files when you interact with our Websites and Services. This information includes internet protocol (IP) addresses as well as browser type, internet service provider, URLs of referring/exit pages, operating system, date/time stamp, information you search for, locale and language preferences, identification numbers associated with your devices, your mobile carrier, and system configuration information. Occasionally, we connect personal information to information gathered in our log files as necessary to improve our Websites and Services. In such a case, we would treat the combined information in accordance with this Policy. Avica requires this information in order for the Website and Services to work as optimally as possible (for example, to display content correctly and to keep the Website and Services secure), the processing of these personal data is therefore necessary for the purposes of the legitimate interests pursued by Avica and are stored for 1 year.',
        ],
      },
      {
        subTitle: 'Analytics:',
        text: [
          'We collect analytics information when you use the Websites to help us improve them. We may also share anonymous data about your actions on our Websites with third-party service providers of analytics services.',
          'We also use mobile analytics software to allow us to better understand the functionality of our mobile application on your device. This software may record information such as how often you use the mobile application, the events that occur within the mobile application, aggregated usage, performance data, and where the mobile application was downloaded from. We do not link the information we store within the analytics software to any personally identifiable information you submit within the mobile application. The processing of personal data is necessary for the purposes of the legitimate interests pursued by Avica, in order to improve services and will not be retained for longer than 2 years.',
        ],
      },
    ],
  },
  {
    title:
      '5. Information We Collect from Subscribers through our Services Account and Registration Information:',
    content: [
      {
        text: [
          'We ask for and may collect personal information about you such as your name, address, phone number, email address, and credit card information, as well as certain related information like your company name and website name, when you register for an account to access or utilize one or more of our Services (an “Account”). We also ask for and collect personal information such as an email address and a name or alias from any individual that you authorize to log into and utilize our Services in connection with Your Account.',
          'If you sign up for a free trial Account, you are not required to enter your credit card information unless and until you decide to continue with a paid subscription to our Services. A third-party intermediary is used to manage credit card processing. This intermediary is not permitted to store, retain, or use your billing information for any purpose except for credit card processing on our behalf.',
          <>
            We refer to any information described above as “Account Information”
            for the purposes of this Policy. The processing of your Account
            Information in this context is necessary for the performance of a
            contract to which you are a party in connection with the use of our
            Service. You cannot register for an Account and make use of our
            services without providing us with these personal details. Except
            for Financial Records such as your name, billing and shipping
            address, invoice records, and/or agreements between you and Avica
            (“Financial Records”), your Account Information is stored for as
            long as you have an Account and will be deleted by contacting{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            . Avica has a legal obligation to retain Financial Records for a
            period of 7 years from the date of the invoice transaction.
          </>,
        ],
      },
      {
        subTitle: 'Other Submissions:',
        text: [
          'We ask for and may collect personal information from you when you submit web forms on our Websites or as you use interactive features of the Websites, including, participation in surveys, contests, promotions, sweepstakes, programs (including reseller, referral, beta, and early access program applications), requesting customer support, or otherwise communicating with us. The processing of personal information is necessary for the purposes of the legitimate interests pursued by Avica, in order to interact with you. Your personal data is stored for a period of one month after responding to your message, resolving your issue or the end of the survey, contest, promotion, sweepstake or program.',
        ],
      },
      {
        subTitle: 'Newsletters:',
        text: [
          <>
            If you have signed up for Avica’s newsletters, we will send you
            information on the development of our Services. If, at any moment,
            you do not wish to receive emails of Avica anymore, you can
            unsubscribe by using the opt-out option that is provided in every
            email that we send you. You may also unsubscribe by sending an email
            to{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            . To subscribe, you have to provide us with your email address. We
            process this personal information on the basis of your consent,
            which you may withdraw at any point through unsubscribing. We retain
            your e-mail address and the data that show that you have signed up
            for the newsletter for as long as you are subscribed to the
            newsletter and no longer than 1 year after you unsubscribe.
          </>,
        ],
      },
      {
        subTitle: 'Attendee Information:',
        text: [
          'We ask for and may collect personal information such as your name, address, phone number and email address when you register for or attend a sponsored event or other events at which any member of the Avica team participates. The processing of your personal data is therefore necessary for the performance of a contract to which you are a party. You cannot register for an event without providing us with these personal details. Your personal information is deleted in accordance with Newsletters section of this policy.',
        ],
      },
      {
        subTitle: 'Desktop software and Mobile Application:',
        text: [
          <>
            When you download, or install, and use our Services, we collect
            following information: (a) Device information including device
            names, the type of device you use, its IP address and MAC address,
            and operating system version and (b) Session information including
            session start time, end time, speed of access to Avica servers in
            different regions, our desktop software or mobile application crash
            dumps, or error logs. Avica requires this information in order for
            the Services to work as optimally as possible (for example, to
            display content correctly and to keep the Services secure), the
            processing of these personal data is therefore necessary to perform
            our contract with you and can be transferred to customer or deleted
            by contacting{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            .
          </>,
        ],
      },
    ],
  },
  {
    title: '6. Information From Third Party Services:',
    content: [
      {
        text: [
          'We may also obtain other information, including personal information, from third parties. For example, we may have access to device information from a third-party RMM (remote monitoring and management) service who integrates with our Services. Any access that we may have to such information from a third-party RMM service is in accordance with the authorization procedures determined by that service. You should check your privacy settings on these third-party services to understand and change the information sent to us through these services.',
        ],
      },
    ],
  },
  {
    title: '7. How we use your personal Information',
    content: [
      {
        subTitle: 'General Uses:',
        text: [
          <>
            We use the information we collect about you (including personal
            information, to the extent applicable) for a variety of purposes,
            including to (a) provide, operate, maintain, improve, and promote
            the Services; (b) enable you to access and use the Services; (c)
            process and complete transactions, and send you related information,
            including purchase confirmations and invoices; (d) send
            transactional messages, including responses to your comments,
            questions, and requests; provide customer service and support; and
            send you technical notices, updates, security alerts, and support
            and administrative messages; (e) send promotional communications,
            such as providing you with information about products and services,
            features, surveys, newsletters, offers, promotions, contests, and
            events; and provide other news or information about us and our
            partners. You can opt out of receiving marketing communications from
            us by contacting us at{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>{' '}
            or following the unsubscribe instructions included in our marketing
            communications; (f) process and deliver contest or sweepstakes
            entries and rewards; (g) monitor and analyze trends, usage, and
            activities in connection with the Websites and Services and for
            marketing or advertising purposes; (h) investigate and prevent
            fraudulent transactions, unauthorized access to the Services, and
            other illegal activities; (i) personalize the Websites and Services,
            including by providing features or advertisements that match your
            interests and preferences; and (j) for other purposes for which we
            obtain your consent.
          </>,
        ],
      },
    ],
  },
  {
    title: '8. Legal Basis for Processing:',
    content: [
      {
        text: [
          'We collect your personal information only where one or more of the following apply: (a) we have your consent to do so, (b) where we need the personal information to perform a contract with you (e.g. to deliver the Services you have requested), or (c) where the processing is in our or a third-party’s legitimate interests (and not overridden by your data protection interests or fundamental rights and freedoms). In some cases, we may also have a legal obligation to collect personal information from you, or may otherwise need the personal information to protect your vital interests or those of another person.',
          <>
            Where we rely on your consent to process the personal information,
            you have the right to withdraw or decline your consent at any time.
            Please note that this does not affect the lawfulness of the
            processing based on consent before its withdrawal. If you wish to
            withdraw your consent or if you have any questions about or need
            further information concerning the legal basis on which we collect
            and use your personal information, please contact us at{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            .
          </>,
        ],
      },
    ],
  },
  {
    title: '9. Sharing of Information Collected',
    content: [
      {
        subTitle: 'Third-Party Service Providers:',
        text: [
          'We share information, including personal information, with our third-party service providers that we use to provide hosting for and maintenance of our Websites, application development, backup, storage, payment processing, email notification service, analytics and other services for us. These third-party service providers may have access to or process your personal information for the purpose of providing these services for us. We do not permit our third-party service providers to use the personal information that we share with them for their marketing purposes or for any other purpose than in connection with the services they provide to us.',
        ],
      },
      {
        subTitle:
          'Compliance with Laws and Law Enforcement Requests; Protection of Our Rights:',
        text: [
          <>
            In certain situations, we may be required to disclose personal
            information in response to lawful requests by public authorities,
            including to meet national security or law enforcement requirements.
            We may disclose personal information to respond to subpoenas, court
            orders, or legal processes, or to establish or exercise our legal
            rights or defend against legal claims. We may also share such
            information if we believe it is necessary in order to investigate,
            prevent, or take action regarding illegal activities, suspected
            fraud, situations involving potential threats to the physical safety
            of any person, violations of our Terms of Service, available at{' '}
            <a
              className="link"
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/terms-and-conditions.html
            </a>{' '}
            , or as otherwise required by law.
          </>,
        ],
      },
      {
        subTitle: 'Testimonials:',
        text: [
          'From time to time, we may post testimonials on the Websites that may contain personal information. We obtain your consent to post your name along with your testimonial.',
        ],
      },
      {
        subTitle: 'Avica Sharing:',
        text: [
          'We may share information, including personal information, with any member of the Avica team. Moreover, we may share your personally identifiable information and/or aggregate information with a parent, subsidiary or affiliate entity in our corporate family. We may transfer your personally identifiable information and/or aggregate information to a successor entity upon a merger, consolidation or other corporate reorganization in which Avica participates or to a purchaser of all or substantially all of Avica’s assets to which the Websites or the Services relate.',
        ],
      },
      {
        subTitle: 'With Your Consent:',
        text: [
          'We may also share personal information with third parties when we have your consent to do so.',
        ],
      },
    ],
  },
  {
    title: '10. How Long We Retain Your Personal Information',
    content: [
      {
        text: [
          'We will retain your personal information for as long as is needed to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is completed automatically after a period of 2 years.',
          'For personal information that we process on behalf of our Subscribers, we will retain such personal information in accordance with the terms of our agreement with them, subject to applicable law.',
        ],
      },
    ],
  },
  {
    title: '11. Your Privacy Rights',
    content: [
      {
        text: [
          <>
            Upon request we will provide you with information about whether we
            hold, or process on behalf of a third party, any of your personal
            information. To request this information please contact us at
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            . Subscribers to our Services may update or change their Account
            Information by editing their profile or organization record or by
            contacting{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>{' '}
            for more detailed instructions. To make a request to have personal
            information maintained by us returned to you or removed, please
            email{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            . Requests to access, change, or remove your information will be
            handled within thirty (30) days.
          </>,
          'An individual who seeks access to, or who seeks to correct or, amend inaccuracies in, or delete personal information stored or processed by us on behalf of a Subscriber should direct his/her query to the Subscriber (the data controller). Upon receipt of a request from one of our Subscribers for us to remove the data, we will respond to their request within thirty (30) days. We will retain personal information that we store and process on behalf of our Subscribers for as long as needed to provide the Services to our Subscribers. We will retain and use this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.',
          <>
            The security of your personal information is important to us. We
            follow generally accepted standards to protect the personal
            information submitted to us, both during transmission and once it is
            received. If you have any questions about the security of your
            personal information, you can contact us at{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
            .
          </>,
          'If you are a Subscriber or otherwise provide us with personal information in connection with your use of our Websites or Services, we will delete this information upon your request, provided that, notwithstanding such request, this information may be retained for as long as you maintain an Account for our Services, or as needed to provide you with our Services, comply with our legal obligations, resolve disputes and enforce our agreements.',
        ],
      },
    ],
  },
  {
    title: '12. International Transfer Of Information Collected',
    content: [
      {
        text: [
          'To facilitate our global operations, as well as to provide you with the Services you subscribed, we may transfer and access such personal information from around the world, including from other countries in which Avica has operations for the purposes described in this Policy. We may also transfer your personal information to our third-party processors, who may be located in a different country to you. Such countries may have laws which are different, and potentially not as protective, as the laws of your own country. Avica has signed Data Processing Agreement (“DPA”) with our third-party processors as contractual safeguard to ensure equivalent obligations as those required from Avica are satisfied. Some of our service providers are located outside the European Economic Area (EEA), the United States, and China. To comply with EU data protection laws around international data transfer, service providers outside the EEA are only allowed to process your personal data in accordance with a contract entered into between Avica and the service provider, incorporating the European Commission’s Standard Contractual Clauses, which ensure that adequate data protection arrangements are in place (Article 46.1(c) GDPR), or if another safeguard for international transfer detailed in GDPR Chapter V applies. For more information on where and how the relevant document may be accessed or obtained, please contact us.',
        ],
      },
    ],
  },
  {
    title: '13. Children’s Privacy',
    content: [
      {
        text: [
          'We recognize the privacy interests of children and we encourage parents and guardians to take an active role in their children’s online activities and interests. The Websites are not intended for children under the age of 13. We do not target its services or the Websites to children under 13. We do not knowingly collect personally identifiable information from children under the age of 13. If you become aware that we are processing the personal information of children under the age of 13, please contact us and we will delete such data as soon as possible.',
        ],
      },
    ],
  },
  {
    title: '14. Links to Third Party Sites',
    content: [
      {
        text: [
          'The Websites may provide links to other websites or resources over which we do not have control (“External Web Sites”). Such links do not constitute an endorsement by Avica of those External Web Sites. You acknowledge that we are providing these links to you for convenience only. We are not responsible for the content of such External Web Sites. Your use of External Web Sites is subject to the terms of use and privacy policies located at such External Web Sites.',
        ],
      },
    ],
  },
  {
    title: '15. Security',
    content: [
      {
        text: [
          'The security of your personal information is important to us. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once it is received. We employ procedural and technological measures, consistent with industry practices to protect your personally identifiable information. Such measures are reasonably designed to help protect your personally identifiable information from loss, unauthorized access, disclosure, alteration or destruction. We use firewalls, password protection, encryption, secure socket layer and take other physical security measures and place internal restrictions on whom within Avica may access your data to help prevent unauthorized access to your personally identifiable information. If you have any questions regarding this Policy please contact us:',
        ],
      },
    ],
  },
  {
    title: 'Contact Us',
    content: [
      {
        text: [
          <>
            By e-mail:{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
          </>,
          'By mail: 10 ANSON ROAD#27-18INTERNATIONAL PLAZA SINGAPORE (079903)',
          <a className="link" href="mailto:service@avica.link" rel="nofollow">
            service@avica.link
          </a>,
          'This Policy was last updated: July 30, 2023',
          'This policy may be translated into different languages. In case of any conflicts or inconsistencies between the English version or any translation hereof, the English version shall prevail and govern.',
        ],
      },
    ],
  },
]
